import useStore from 'store';
import { AuthorizationContext } from 'context';
import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';

const PrivateRoute = ({ component: Component, location, scope, ...rest }) => {
  const store = useStore();
  const user = store?.user;
  const context = useContext(AuthorizationContext);
  const [permissions] = context || [];

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const isUserNotAuthorized = !!scope && permissions?.[scope];

  useEffect(() => {
    if (!user && location.pathname !== `/`) {
      setIsLoginModalOpen(true);
    }

    if (!user && location.pathname !== `/`) {
      navigate('/', { state: { isLoginModalOpen } });
    }

    if (!user && location.pathname !== `/`) {
      navigate('/', { state: { isLoginModalOpen } });
    }

    if (isUserNotAuthorized && location.pathname !== `/`) {
      navigate('/admin/trials/list');
    }
  });

  if (!user) {
    return null;
  }

  if (isUserNotAuthorized) {
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
